<template>
    <div>
        <div class="upwraperlist">

            <table>
                <tr>
                    <th class="index"></th>

                    <th>Part number</th>
                    <th>{{ $t('labels.model') }}</th>
                    <th>{{ $t('labels.description') }}</th>
                    <th>{{ $t('labels.unitValue') }}</th>
                    <th>{{ $t('labels.qtd') }}</th>
                    <th>{{ $t('labels.totalValue') }}</th>
                    <th>{{ $t('labels.dataProgram') }}</th>
                    <th>{{ $t('labels.segment') }}</th>
                    <th>{{ $t('labels.productLink') }}</th>
                    <th>{{ $t('labels.finalCustomer') }}</th>
                </tr>
                <tr
                    v-for="(item, index) in rows"
                    :key="index">
                    <td class="index">{{item.index}}</td>
                    <td>{{item.data.partNumber}}</td>
                    <td>{{item.data.model}}</td>
                    <td>{{item.data.description}}</td>
                    <td>{{item.data.value}}</td>
                    <td>{{item.data.quantity}}</td>
                    <td>{{item.data.productValue}}</td>
                    <td>{{item.data.scheduledDate}}</td>
                    <td>{{item.data.segment}}</td>
                    <td>{{item.data.link}}</td>
                    <td>{{item.data.finalClient}}</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>

export default {
    computed: {
        rows() {
            return this.$store.state.order.uploader.rows
        }
    },
}
</script>

<style lang="scss">
.upwraperlist {
    width: 100%;
    max-height: 400px;
    overflow: auto auto;

    th, td {
        min-width: 140px;
        text-align: left;

        &.index {
            min-width: auto;
        }
    }
}
</style>
