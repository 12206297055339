<template>
    <f-card>
        <div v-if="order.type == 'funnel'">
            <v-text-field
                outlined
                dense
                hide-details="auto"
                :label="$t('labels.funnel')"
                v-model="order.funnel_number" />
            <br />
            <v-radio-group
                v-model="order.stock"
                row>
                <v-radio
                    :label="$t('labels.onStock')"
                    :value="true" />
                <v-radio
                    :label="$t('labels.outStock')"
                    :value="false" />
            </v-radio-group>
        </div>
        <div v-if="order.type == 'contract'">
            <v-select
                v-model="order.contract_id"
                outlined
                dense
                hide-details="auto"
                :items="contracts"
                item-text="code"
                item-value="id"
                :label="$t('labels.contract')" />
        </div>
    </f-card>
</template>

<script>
export default {
    data: () => ({
        funnels: [],
        contracts: []
    }),
    computed: {
        order() {
            return this.$store.state.order.view
        }
    },
    created() {
        this.getData()
    },
    methods: {
        async getData() {
            // const funnels = await this.$store.dispatch(
            //     'distributor/listFunnels',
            //     this.order.distributor_id
            // )
            // this.funnels = funnels
            const contracts = await this.$store.dispatch(
                'distributor/listContracts',
                this.order.distributor_id
            )
            this.contracts = contracts
        }
    }
}
</script>
