<template>
  <tr>
    <td>
      <span @click="remove"><v-icon>mdi-delete</v-icon></span>
    </td>
    <td>
      <input
        type="text"
        ref="first_field"
        v-model="rowData.partNumber"
        @input="handleEvent"
      />
    </td>
    <td><input type="text" v-model="rowData.model" @input="handleEvent" /></td>
    <td>
      <input type="text" v-model="rowData.description" @input="handleEvent" />
    </td>
    <td>
      <input
        type="text"
        v-mask="'#############'"
        v-model="rowData.quantity"
        @input="handleEvent"
      />
    </td>
    <td>
      <input
        type="text"
        v-model="rowData.value"
        v-mask="currencyMask"
        @input="handleEvent"
      />
    </td>
    <td>
      <input
        type="text"
        :disabled="true"
        v-model="rowData.totalValue"
        @input="handleEvent"
      />
    </td>
    <td>
      <input
        type="text"
        v-model="rowData.scheduledDate"
        v-mask="'##/##/####'"
        @input="handleEvent"
      />
    </td>
    <td>
      <input type="text" v-model="rowData.finalClient" @input="handleEvent" />
    </td>
    <td>
      <input type="text" v-model="rowData.segment" @input="handleEvent" />
    </td>
    <td>
      <input
        type="text"
        v-model="rowData.link"
        @input="handleEvent"
        @keydown="check"
        @focus="setCtl"
      />
    </td>
  </tr>
</template>

<script>
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import numberFormater from "../../helpers/number_formater";

var currencyMask = createNumberMask({
  prefix: "R$ ",
  allowDecimal: true,
  decimalSymbol: ",",
  decimalLimit: 9,
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ".",
  allowNegative: false,
  requireDecimal: false,
});
export default {
  props: ["value", "index"],
  data: () => ({
    currencyMask,
    rowData: {},
  }),
  computed: {
    totalVal() {
      var clrNumber = this.clearCurrency(this.rowData.value);
      this.rowData.rawValue = clrNumber;
      if (this.rowData.quantity && clrNumber) {
        return parseInt(this.rowData.quantity) * clrNumber;
      }
      return 0;
    },
  },
  watch: {
    totalVal(value) {
      this.rowData.rawTotalValue = value;
      this.rowData.totalValue = numberFormater.money(value, {
        decimalLength: 2,
      });
      this.$store.commit("order/setTotal");
    },
  },
  created() {
    if (this.value) {
      this.rowData = this.value;
    }
  },
  mounted() {
    this.$refs.first_field.focus();
  },
  methods: {
    clearCurrency(value) {
      var result;
      try {
        result = value.replace(/[^0-9,]/g, "").replace(",", ".");
        return parseFloat(result);
      } catch (e) {
        //
      }
    },
    setCtl() {
      this.$store.commit("order/productList_setCtl", this.index);
    },
    check(e) {
      if (
        this.index == this.$store.state.order.inserter.rows.length - 1 &&
        e.keyCode == 9
      ) {
        e.preventDefault();
        this.$store.commit("order/productList_newLine");
      }
    },
    handleEvent(_) {
      this.$emit("input", this.rowData);
    },
    remove() {
      this.$store.commit("order/productList_remove", this.index);
    },
  },
};
</script>
