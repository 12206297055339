<template>
  <f-card>
    <v-row>
      <v-col cols="12">
        <f-card-title>{{ $t("pages.orders.new.optionalData") }}</f-card-title>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="order.final_client"
          outlined
          dense
          hide-details
          :label="$t('labels.finalCustomer')"
        />
      </v-col>
      <v-col cols="6"></v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="order.voucher_code"
          outlined
          dense
          hide-details
          label="Voucher"
        />
      </v-col>
      <v-col cols="6"></v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-textarea
          v-model="order.comment"
          outlined
          dense
          hide-details
          :label="$t('labels.observation')"
        />
      </v-col>
    </v-row>
  </f-card>
</template>

<script>
export default {
  computed: {
    order() {
      return this.$store.state.order.view;
    },
  },
};
</script>
