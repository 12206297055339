<template>
	<f-card>
		<v-row>
			<v-col cols="12">
				<f-card-title>{{ $t("pages.orders.new.data") }}</f-card-title>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="6">
				<v-text-field
					v-model="order.number"
					:disabled="flags.isEditing"
					outlined
					dense
					hide-details="auto"
					:label="$t('labels.orderNumber')" />
			</v-col>
			<v-col cols="6"></v-col>
		</v-row>
		<v-row>
			<v-col cols="6">
				<v-select
					v-model="order.payment_method_id"
					outlined
					dense
					hide-details="auto"
					:items="paymentMethodsTranslated"
					item-text="label"
					item-value="id"
					:label="$t('labels.paymentCondition')" />
			</v-col>
			<v-col cols="6">
				<v-checkbox
					v-model="order.parcial_billing"
					hide-details="auto"
					:label="$t('labels.partialPayment')" />
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="6">
				<v-checkbox
					v-model="shippingMod"
					hide-details="auto"
					:label="$t('labels.clientWithdraws')" />
			</v-col>
			<v-col cols="6"></v-col>
		</v-row>
		<v-row>
			<v-col cols="6">
				<v-select
					v-model="order.shipping_type"
					:disabled="shippingMod"
					outlined
					dense
					hide-details="auto"
					:items="['CIF', 'FOB']"
					:label="$t('labels.typeShipping')" />
			</v-col>
			<v-col cols="6">
				<v-select
					v-model="order.shipping_code"
					:disabled="shippingDisabled || shippingMod"
					outlined
					dense
					hide-details="auto"
					:items="$store.state.order.selects.shippingCompanies"
					item-text="name"
					item-value="protheus_id"
					:label="$t('labels.shipCompany')" />
			</v-col>
		</v-row>
	</f-card>
</template>

<script>
export default {
	data: () => ({
		paymentMethods: [],
		modal: {
			date: "",
			opened: false,
		},
		flags: {
			isEditing: false,
		},
		shippingMod: false,
	}),
	computed: {
		order() {
			return this.$store.state.order.view;
		},
		paymentMethodsTranslated() {
			const labels = this.paymentMethods.map((item) => {
				return {
					id: item.id,
					label: item.description,
				};
			});
			return labels;
		},
		shippingDisabled() {
			if (
				this.order.shipping_type == "CIF" ||
				this.order.shipping_type == "FOB"
			) {
				return false;
			}
			return true;
		},
		modalDate() {
			if (this.modal.date && this.order.shipping_type == "scheduled") {
				const [year, month, day] = this.modal.date.split("-");
				return `${day}/${month}/${year}`;
			}

			return "";
		},
	},
	watch: {
		"order.shipping_type": function (value) {
			if (value) {
				this.$store.dispatch("order/shippingList", value);
			}
		},
		modalDate() {
			if (this.modalDate) {
				const [day, month, year] = this.modalDate.split("/");
				this.order.shipping_date = `${year}-${month}-${day} 00:00:00`;
			} else {
				this.order.shipping_date = null;
			}
		},
		shippingMod(value) {
			// '000001'
			// "shipping_type": "FOB", "shipping_code": "000001"
			if (value) {
				this.order.shipping_type = "FOB";
				this.order.shipping_code = "000001";
				this.$store.dispatch("order/shippingList", "FOB");
			} else {
				this.order.shipping_type = "";
				this.order.shipping_code = "";
			}
		},
	},
	created() {
		this.language = localStorage.getItem("currentLang") || "pt";

		this.getData();
		if (this.$route.name == "order-edit") {
			this.flags.isEditing = true;
		}
	},
	methods: {
		async getData() {
			this.paymentMethods = await this.$store.dispatch("paymentMethods/list");
		},
	},
};
</script>
