<template>
  <f-card>
    <v-row>
      <v-col cols="6">
        <v-select
          outlined
          dense
          :items="type_items"
          item-text="label"
          item-value="value"
          hide-details="auto"
          v-model="order.type"
          :label="$t('labels.type')"
        />
      </v-col>
      <v-col cols="6" />
    </v-row>
  </f-card>
</template>

<script>
export default {
  computed: {
    order() {
      return this.$store.state.order.view;
    },
    type_items() {
      return this.$store.state.order.selects.type.map(type => ({
        ...type,
        label: this.$t(type.label),
      }));
    },
  },
};
</script>
