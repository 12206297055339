import FDistributor from '../../components/orders/Distributor.vue'
import FOrderType from '../../components/orders/OrderType.vue'
// import FFlags from '../../components/orders/Flags.vue'
import FAuxData from '../../components/orders/AuxData.vue'
import FData from '../../components/orders/Data.vue'
import FOptionalData from '../../components/orders/OptionalData.vue'
import FProducts from '../../components/orders/Products.vue'
import FTotalValue from '../../components/orders/TotalValue.vue'
import { getOrderErrorMessages } from '../../helpers/order_utils'

export default {
    components: {
        FDistributor,
        FOrderType,
        // FFlags,
        FAuxData,
        FData,
        FOptionalData,
        FProducts,
        FTotalValue
    },
    data: () => ({
        file: null,
        headers: [],
        flags: {
            editing: false,
            isAdmin: false,
            lockBtns: false
        },
        orderID: null,
        isDistributorSelected: false,
        selInterval: null
    }),
    computed: {
        auxData() {
            if( typeof(this.$store.state.order.view.type) != 'undefined' ){
                const auxDataFields = [ 'funnel', 'contract' ]
                return  auxDataFields.includes( this.$store.state.order.view.type )
            }
            return false
        },
        readyForSend(){
            if(
                this.isDistributorSelected &&
                this.$store.state.order.view.number
            ) {
                return true
            }
            return false
        }
    },
    watch: {
        '$route.name': function( value ) {
            if( value == 'order-create' ){
                this.flags.creating = true
                this.$store.dispatch( 'order/clear', 'view' )
            }
        }
    },
    created() {
        if( this.$route.name == 'order-edit' ) this.flags.editing = true
        if( this.flags.editing ) {
            this.orderID = this.$route.params.id
            this.getData()
        }
        this.checkAdmin()
        this.selInterval = setInterval(function(){
            this.checkSelected()
        }.bind(this),800)
    },
    methods: {
        checkSelected(){
            if( this.$store.state.order.view.cnpj_id ) {
                this.isDistributorSelected = true
                return
            }

            if( this.$route.name == 'order-edit' ) {
                this.isDistributorSelected = true
                return
            }

            this.isDistributorSelected = false
        },
        async checkAdmin() {
            const user = this.$store.state.auth.user
            // is not an admin
            if( user.distributorID ){
                this.flags.isAdmin = false
                this.$store.state.order.view.distributor_id = user.distributorID

            } else {
                this.flags.isAdmin = true
            }
        },
        async getData() {
            await this.$store.dispatch( 'order/get', {
                orderID: this.orderID,
                edit: true
            })
            this.$store.commit( "order/switchView" )
        },
        async up() {
            const result = await fileUploader.generic( this.file, this.$store.state )
            this.headers = result
        },
        async save() {
            if( !this.flags.editing ) {
                this.$store.state.order.preventClear = true

                this.$router.push({
                    name: 'order-preview'
                })

                return
            }

            this.flags.lockBtns = true
            if( this.flags.editing ) {
                await this.$store.dispatch( 'order/update' )
                this.$store.commit( 'snackbar/show', {
                    content: this.$t('snackbar.orderAlterSuccess'),
                    type: "success",
                })
            } else {
                const result = await this.$store.dispatch( 'order/create' )
                if (result.status) {
                    this.$store.commit( 'snackbar/show', {
                      content: this.$t('snackbar.orderAlterSuccess'),
                        type: "success",
                    })
                } else {
                    const message = getOrderErrorMessages(result.body.errors || [])
                    this.$store.commit( 'snackbar/show', {
                        content: message,
                        type: "error",
                    })
                }
            }
            this.$store.dispatch( 'order/clear', 'view' )
            this.flags.lockBtns = false
            this.back()
        },
        async draft() {
            this.flags.lockBtns = true
            if( this.flags.editing ) {
                await this.$store.dispatch( 'order/updateDraft' )
            } else {
                await this.$store.dispatch( 'order/draft' )
            }
            this.$store.commit( 'snackbar/show', {
                content: this.$t('snackbar.draftAlterSuccess'),
                type: "success",
            })
            this.flags.lockBtns = false
            this.back()
        },
        back() {
            this.$router.push({
                name: 'order-list'
            })
        },
        clear() {
            this.$store.dispatch( 'order/clear', 'view' )
        }
    },
    destroyed() {
        clearInterval( this.selInterval )
        this.$store.dispatch( 'order/clear', 'view' )
    }
}
