<template>
    <f-card>
        <v-row>
            <v-col cols="12">
                <f-card-title>{{ $t('pages.orders.new.products') }}</f-card-title>
            </v-col>
        </v-row>
        <v-row v-if="view == 'home'">
            <v-col cols="12" align="center">
                <v-btn @click="setView('manual')">{{ $t('labels.insertManualProduct') }}</v-btn>&nbsp;&nbsp;&nbsp;
                <v-btn @click="setView('sendfile')">{{ $t('labels.sendXls') }}</v-btn>
            </v-col>
        </v-row>
        <v-row v-if="view == 'manual'">
            <v-col cols="12">
                <div class="backView">
                    <span @click="backView"><v-icon>mdi-arrow-left</v-icon> voltar</span>
                </div>
                <f-products-inserter />
            </v-col>
        </v-row>
        <v-row v-if="view == 'sendfile'">
            <v-col cols="12">
                <div class="backView">
                    <span @click="backView"><v-icon>mdi-arrow-left</v-icon> voltar</span>
                </div>
                <f-products-uploader />
            </v-col>
        </v-row>
        <v-row v-if="view == 'uploader-list'">
            <v-col cols="12">
                <f-products-uploader-list />
            </v-col>
        </v-row>
    </f-card>
</template>

<style lang="scss">
.backView {
    text-align: right;
    margin-bottom: 16px;

    i {
        font-size: 16px !important;
    }
}
#f-fcs-bg {
    background-color: rgba(0,0,0,.5);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    #f-fcs-card {
        width: 80%;
        margin: 40px auto;
        background-color: #fff;
        border-radius: 20px;
        padding: 40px;
    }
}
</style>

<script>
import FProductsInserter from './ProductsInserter.vue'
import FProductsUploader from './ProductsUploader.vue'
import FProductsUploaderList from './ProductsUploaderList.vue'

export default {
    components: {
        FProductsInserter,
        FProductsUploader,
        FProductsUploaderList,
    },
    data: () => ({
        view: 'home',
        file: null,
        fileHeaders: [],
        showingOff: true
    }),
    created(){
        this.$store.subscribe((mutation, state) => {
			if (mutation.type === "order/switchView") {
                if( state.order.viewCtl == "uploader-list" ){
                    this.setView('uploader-list')
                } else {
                    this.setView('manual')
                }
            }
        })
    },
    mounted() {
        // if( !document.getElementById('products-cdn-polyfill') ) {
        //     let cdnPolyfill = document.createElement('script')
        //     cdnPolyfill.setAttribute('id', 'products-cdn-polyfill')
        //     cdnPolyfill.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/babel-polyfill/6.26.0/polyfill.js')
        //     document.head.appendChild(cdnPolyfill)
        // }
        // if( !document.getElementById('products-cdn-exceljs') ) {
        //     let exceljs = document.createElement('script')
        //     exceljs.setAttribute('id', 'products-cdn-exceljs')
        //     exceljs.setAttribute('src', '/scripts/exceljs.js')
        //     document.head.appendChild(exceljs)
        // }

        // this.setView('manual')

    },
    methods: {
        backView() {
            this.view = 'home'
        },
        async setView( view ){
            this.view = view
            if( view == 'sendfile' ){
                // get distributor data
                // const distributor = await this.$store.dispatch( 'distributor/config', this.$store.state.view.distributor_id )

                // check config
                // var configCheck = false
                // if(
                //     distributor.config &&
                //     distributor.config.columns
                // ){
                //     configCheck = true
                // }

                // // ? set columns relations
                // if( !configCheck ){
                //     // columns relation system - set
                // }
                // read file data

                // input data on product list ( product inserter )

                // console.log( distributor, configCheck )
            } else {
                // this.view = view
            }
        },
        async up() {
            // const workbook = new ExcelJS.Workbook()
            // const reader = new FileReader()

            // reader.onloadend = async function( _ ){
            //     this.setView('sendfile')

            //     await workbook.xlsx.load( reader.result )
            //     var headers = []
            //     workbook.getWorksheet(1).getRow(1).eachCell( (cell, cellNumber) => {
            //         headers[cellNumber] = cell.value
            //     })
            //     // console.log( headers )
            //     this.fileHeaders = headers
            // }.bind(this)
            // reader.readAsArrayBuffer(this.file)


            // console.log( reader.readAsArrayBuffer(this.file) )

        }
    }
}
</script>
