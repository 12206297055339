<template>
  <f-card>
    <div v-if="isAdmin">
      <v-row>
        <v-col cols="6">
          <v-select
            v-model="order.distributor_id"
            outlined
            dense
            :disabled="editing"
            hide-details="auto"
            :items="distributors"
            item-text="name"
            item-value="id"
            :label="$t('labels.distributor')"
          />
        </v-col>
        <v-col cols="6">
          <v-select
            v-model="order.cnpj_id"
            outlined
            dense
            :disabled="editing || !cnpjs.length"
            hide-details="auto"
            :items="cnpjs"
            item-text="selectvalue"
            item-value="id"
            :label="$t('labels.companyId')"
          />
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <v-row>
        <v-col cols="6">
          <v-select
            v-model="order.cnpj_id"
            outlined
            dense
            :disabled="editing || !cnpjs.length"
            hide-details="auto"
            :items="cnpjs"
            item-text="selectvalue"
            item-value="id"
            label="CNPJ"
          />
        </v-col>
      </v-row>
    </div>
  </f-card>
</template>

<script>
export default {
  data: () => ({
    distributors: [],
    cnpjs: [],
    editing: false,
    isAdmin: false,
  }),
  computed: {
    order() {
      return this.$store.state.order.view;
    },
  },
  watch: {
    "order.distributor_id": function (value) {
      this.order.cnpj_id = null;
      this.getCNPJs();
    },
  },
  created() {
    this.getData();
    if (this.$route.name == "order-edit") {
      this.editing = true;
    }
    this.checkAdmin();
  },
  methods: {
    checkAdmin() {
      const user = this.$store.state.auth.user;
      if (user.distributorID) {
        this.isAdmin = false;

        this.order.cnpj_id = null;
        this.getCNPJs();
      } else {
        this.isAdmin = true;
      }
    },
    async getData() {
      const distributors = await this.$store.dispatch("distributor/list");
      this.distributors = distributors;
    },
    async getCNPJs() {
      const cnpjs = await this.$store.dispatch(
        "distributor/listCNPJs",
        this.order.distributor_id
      );
      this.cnpjs = cnpjs.map((item) => {
        item["selectvalue"] = `${item.title} - ${item.cnpj}`;
        return item;
      });

      if (this.cnpjs.length == 1) {
        this.order.cnpj_id = this.cnpjs[0].id;
      }
    },
  },
};
</script>
