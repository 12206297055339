<template>
  <div>
    <div id="table_container">
      <table :key="'rf' + refreshKey">
        <thead>
          <tr>
            <th class="m_">&nbsp;</th>
            <th>Part number</th>
            <th>{{ $t("labels.model") }}</th>
            <th>{{ $t("labels.description") }}</th>
            <th>{{ $t("labels.qtd") }}</th>
            <th>{{ $t("labels.unitValue") }}</th>
            <th>{{ $t("labels.totalValue") }}</th>
            <th>{{ $t("labels.dataProgram") }}</th>
            <th>{{ $t("labels.finalCustomer") }}</th>
            <th>{{ $t("labels.segment") }}</th>
            <th>{{ $t("labels.productLink") }}</th>
          </tr>
        </thead>
        <tbody>
          <f-row
            v-for="(item, index) in rows"
            :key="index"
            v-model="item.data"
            :index="index"
          />
        </tbody>
      </table>
    </div>
    <br />
    <v-btn @click="newLine"
      ><v-icon>mdi-plus</v-icon>{{ $t("labels.newLine") }}</v-btn
    >
  </div>
</template>

<script>
import FRow from "./ProductsInserterRow.vue";

export default {
  components: {
    FRow,
  },
  data: () => ({
    refreshKey: 1,
  }),
  computed: {
    rows() {
      return this.$store.state.order.inserter.rows;
    },
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "order/productList_newLine") {
        // console.log('NEW',this.rows.length, this.$store.state.order.newLineCtl + 1)
        if (this.rows.length == this.$store.state.order.newLineCtl + 1) {
          this.newLine();
        }
      } else if (mutation.type === "order/productList_remove") {
        this.refreshKey++;
      }
    });

    if (this.$route.name != "order-edit") {
      this.newLine();
    }
  },
  methods: {
    newLine() {
      this.rows.push({ index: this.rows.length });
    },
  },
};
</script>

<style lang="scss">
#table_container {
  max-width: 100%;
  overflow: auto;
  max-height: 500px;

  table {
    border-collapse: collapse;

    th {
      text-align: left;
      min-width: 160px;

      &.m_ {
        min-width: auto;
      }
    }

    td {
      input {
        border: 1px solid rgb(156, 156, 156);
        padding: 3px 5px;
      }
    }
  }
}
</style>
