export default {
    data: () => ({
        file: null,
        fileHeaders: [],
        distributorHeaders: false,
        configHeaders: {},
        view: 'home',
        workbook: null
    }),
    computed: {
        distID(){
            return this.$store.state.order.view.distributor_id
        },
        hasFile(){
            if( !this.file ){
                return false
            } else {
                return true
            }
        },
        loading(){
            return !this.$store.state.system.loading.done
        }
    },
    created() {
        // load headers
        this.loadHeaders()
    },
    methods: {
        async loadHeaders() {
            this.$store.dispatch( 'system/loading', 'data' )
            const distributor = await this.$store.dispatch( 'distributor/config', this.distID )
            const distConfig = JSON.parse( distributor.config )
            this.$store.dispatch( 'system/loading', 'done' )

            if(
                distConfig &&
                distConfig.xlsHeaders
            ){
                this.distributorHeaders = distConfig.xlsHeaders
            } else {
                // console.log('fail')
            }
        },
        async up() {
            this.checkHeaders()
        },
        async checkHeaders(){
            if( !this.distributorHeaders ){
                this.$store.dispatch( 'system/loading', 'data' )
                const headers = await this.$store.dispatch(
                    'order/getHeaders',
                    this.file
                )
                this.$store.dispatch( 'system/loading', 'done' )
                if( headers == false ) return

                this.fileHeaders = headers
                this.view = 'config'
            } else {
                this.transferData()
            }
        },
        async saveConfig(){
            // validate
            if( true ) {
                // save config
                await this.$store.dispatch( 'distributor/configSave', this.configHeaders )
                this.transferData()
            } else {
                // show alerts
            }
        },
        async transferData() {
            this.$store.dispatch( 'system/loading', 'data' )
            const result = await this.$store.dispatch(
                'order/dataProccess',
                this.file
            )
            this.$store.dispatch( 'system/loading', 'done' )
            if( result ){
                this.$store.commit( "order/switchView", "uploader-list" )
            }
        }
    }
}
